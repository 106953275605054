import React from 'react';
import GymCard from '../components/GymCard';
import GoogleMapEmbed from '../components/GoogleMaps';

const GymDisplay = React.memo(({ gyms, viewOption, location }) => {
    return (
        <div style={styles.container} className='color-main'>
            {viewOption === 'grid' ? (
                <div style={gyms.length > 0 ? styles.cardDeck : styles.noGymsContainer}>
                    {gyms.length > 0 ? (
                        gyms.map((gym) => <GymCard key={gym._id} gym={gym} style={styles.card} />)
                    ) : (
                        <p style={styles.noGymsText}>Hey! We're doing maintenance, please check back in a little bit.</p>
                    )}
                </div>
            ) : (
                <div style={styles.mapContainer}>
                    <GoogleMapEmbed lat={location.lat} lon={location.lon} location={'jiu jitsu'} />
                </div>
            )}
        </div>
    );
});

// Styles for the GymDisplay component
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '15px',
        paddingTop: '0', 
    },
    cardDeck: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', // Responsive grid
        gap: '20px',
        width: '100%',
        padding: '20px',
        justifyContent: 'center',
    },
    card: {
        flex: '1 1 calc(25% - 20px)',
        boxSizing: 'border-box',
        marginBottom: '20px',
    },
    noGymsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        width: '100%',
    },
    noGymsText: {
        fontSize: '18px',
        color: '#666',
        textAlign: 'center', // Center text inside the container
    },
    mapContainer: {
        width: '100%',
        height: '500px',
        marginTop: '20px',
    },
};

export default GymDisplay;
