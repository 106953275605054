import React, { useState, useEffect } from 'react';
import upIcon from '../assets/icons/up-icon.png'; // Import the up icon image

const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false); // State to track hover

    // Show button when page is scrolled down
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const styles = {
        button: {
            position: 'fixed',
            bottom: '40px',
            right: '40px',
            backgroundColor: 'transparent', // Make the background transparent
            border: 'none',
            cursor: 'pointer',
            zIndex: 1000,
            transition: 'opacity 0.3s ease, transform 0.3s ease', // Add transition for hover effect
            opacity: isVisible ? 1 : 0,
            pointerEvents: isVisible ? 'auto' : 'none',
            transform: isHovered ? 'scale(1.2)' : 'scale(1)', // Scale effect on hover
        },
        icon: {
            width: '50px', // Icon size
            height: '50px',
        },
    };

    return (
        <button
            onClick={scrollToTop}
            style={styles.button}
            aria-label="Back to Top"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img src={upIcon} alt="Back to Top" style={styles.icon} />
        </button>
    );
};

export default BackToTopButton;
