import React, { useState, useContext, useEffect } from 'react';
import { GymContext } from '../contexts/GymContext';
import Loading from '../components/Loading';
import Error from '../components/Error';
import GymDisplay from '../components/GymDisplay';
import SearchComponent from '../components/SearchComponent'; 
import BackToTopButton from '../components/BackToTopButton';
// import GymFilters from '../components/GymFilters';

function Home() {
    const { gyms, loading, error } = useContext(GymContext);
    const [viewOption, setViewOption] = useState('grid');
    const [searchText, setSearchText] = useState(''); // Added state for search text

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Filter gyms based on search text
    const filteredGyms = gyms.filter(gym =>
        gym.name.toLowerCase().includes(searchText.toLowerCase()) ||  // Match by name
        gym.location.formatted_address.toLowerCase().includes(searchText.toLowerCase()) // Match by address
    );

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;
    
    return (
        <div>
            
            {/* Filter Section */}
            {/* <div style={styles.rowOne}>
                <GymFilters />
            </div> */}

            <h2 style={{ margin: '10px' }}>Explore Local BJJ Gyms</h2>
            

            {/* Search Section */}
            <div style={styles.rowTwo}>
                <SearchComponent
                    searchText={searchText} 
                    onSearchChange={setSearchText} 
                />
            </div>

            

            {/* Gym Display Section */}
            <div style={ styles.rowThree }>
                <GymDisplay gyms={filteredGyms} viewOption={viewOption} />
            </div>

            <div>
                <BackToTopButton />
            </div>
        </div>
    );
}

const styles = {
    rowOne: {
    },
    heading: {
        padding: '20px',
        fontSize: '2rem',
    },
    subHeading: {
        fontSize: '1.3rem',
    },
    paragraph: {
        padding: '20px',
        fontSize: '1.1rem',
    },
    rowTwo: {
        padding: '20px',
        backgroundColor: 'whitesmoke',
        borderTop: '1px solid lightgrey',
        borderBottom: '1px solid lightgrey',
    },
    buttonContainer: {
        marginBottom: '20px',
    },
    rowThree: {
        backgroundColor: 'whitesmoke',
    }
};

export default Home;
