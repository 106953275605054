import React from 'react';
import { useNavigate } from 'react-router-dom';
import SocialMediaIcons from './SocialMediaIcons';  // Import the standalone SocialMediaIcons component

const GymCard = ({ gym }) => {
    const navigate = useNavigate();

    // Styles for the card and social media icons
    const styles = {
        card: {
            borderRadius: '15px',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease',
            cursor: 'pointer',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'relative',
        },
        title: {
            fontSize: '24px',
            color: '#333',
            marginBottom: '15px',
        },
        details: {
            fontSize: '15px',
            color: '#999',
            marginBottom: '10px',
        },
        divider: {
            margin: '10px 0',
            borderTop: '1px solid #ddd',
        },
    };

    // Card interaction handlers
    const handleMouseEnter = (e) => {
        e.currentTarget.style.transform = 'scale(1.05)';
    };

    const handleMouseLeave = (e) => {
        e.currentTarget.style.transform = 'scale(1)';
    };

    const handleCardClick = (e) => {
        // Prevent card navigation if the click comes from a social media icon
        if (e.target.closest('a')) return;
        navigate(`/gyms/${gym._id}`);
    };

    return (
        <div
            style={styles.card}
            onClick={handleCardClick}  // Only navigate when clicking outside of icons
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <h2 style={styles.title}>{gym.name}</h2>
            <p style={styles.details}>Address: {gym.location.formatted_address}</p>
            <p style={styles.details}>Phone: {gym.tel}</p>
            <hr style={styles.divider} />
            {/* Reuse SocialMediaIcons component */}
            <SocialMediaIcons gym={gym} />
        </div>
    );
};

export default GymCard;
