import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode directly

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to access the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap around your app
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    // On app startup, check if a token is already saved in local storage
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token); // Decode token to extract user data
                setCurrentUser({
                    token,
                    ...decodedToken, // Spread user data from the token (e.g., firstName, lastName, etc.)
                });
            } catch (error) {
                console.error('Invalid token', error);
                logout(); // Clear any invalid token
            }
        }
    }, []);

    // Function to handle login
    const login = (token) => {
        try {
            const decodedToken = jwtDecode(token); // Decode token to extract user data
            setCurrentUser({
                token,
                ...decodedToken, // Spread user data from the token
            });
            localStorage.setItem('token', token); // Store token in local storage
            console.log('User has been logged in and updated in context');
        } catch (error) {
            console.error('Failed to decode token', error);
        }
    };

    // Function to handle logout
    const logout = () => {
        localStorage.removeItem('token'); // Remove token from local storage
        setCurrentUser(null); // Clear current user
    };

    // Context value containing the current user, login, and logout
    const value = { currentUser, login, logout };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
