import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/Button';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { login } = useAuth();  // Login function from AuthContext
    const navigate = useNavigate();  // Hook to navigate programmatically

    // Form validation function
    const validateForm = () => {
        const newErrors = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        // Password validation (at least 8 characters)
        if (password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;  // Stop form submission if validation fails

        try {
            // Send POST request to backend to log in
            const response = await fetch('/api/v1/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email.trim(),
                    password,
                }),
            });

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                throw new Error(message);
            }

            const data = await response.json();
            console.log('Login successful:', data);

            // Log the user in with the received token and potentially store user details
            login(data.token, data.user);  // Assuming login function takes both token and user data

            // Clear form fields
            setEmail('');
            setPassword('');

            // Redirect to home page after successful login
            navigate('/home');
        } catch (error) {
            console.error('Error during login:', error.message);
            setErrors({ server: 'Invalid email or password. Please try again.' });
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');  // Navigate to the forgot password page
    };

    const handleResetPassword = () => {
        navigate('/reset-password');  // Navigate to the reset password page
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Log In</h2>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        style={styles.input}
                    />
                    {errors.email && <p style={styles.error}>{errors.email}</p>}
                </div>
                <div style={styles.formGroup}>
                    <label style={styles.label}>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={styles.input}
                    />
                    {errors.password && <p style={styles.error}>{errors.password}</p>}
                </div>
                <Button
                    text="Login"
                    type="submit"
                    buttonType="primary"
                />
                {errors.server && <p style={styles.error}>{errors.server}</p>}
            </form>

            {/* Add Forgot Password and Reset Password buttons */}
            <div style={styles.additionalOptions}>
                <button onClick={handleForgotPassword} style={styles.linkButton}>
                    Forgot Password
                </button>
                <button onClick={handleResetPassword} style={styles.linkButton}>
                    Reset Password
                </button>
            </div>

            {/* Add "Don't have an account?" link */}
            <div style={styles.signupRedirect}>
                <p>Don't have an account?</p>
                <button
                    onClick={() => navigate('/signup')}
                    style={styles.signupLink}
                >
                    Sign up here!
                </button>
            </div>
        </div>
    );
};

// Styles for the component
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: '0 auto',
        backgroundColor: '#fff',
    },
    heading: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    form: {
        width: '100%',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '16px',
        color: '#666',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    error: {
        color: 'red',
        fontSize: '12px',
    },
    additionalOptions: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    linkButton: {
        background: 'none',
        border: 'none',
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '14px',
    },
    signupRedirect: {
        marginTop: '20px',
        textAlign: 'center',
    },
    signupLink: {
        background: 'none',
        border: 'none',
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '5px',
    },
};

export default Login;
