import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, Navigate, useLocation as useRouterLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import GymDetails from './pages/GymDetails';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
// import News from './pages/News';
// import UserProfile from './pages/UserProfile';
import AddGym from './pages/AddGym';
import EditGym from './pages/EditGym';
import Contact from './pages/Contact';
import { useAuth } from './contexts/AuthContext';
import { useLocation } from './contexts/LocationContext';
// import LandingPage from './pages/Landing';
import { GymContext } from './contexts/GymContext';

function App() {
  // const { currentUser } = useAuth();
  const { location } = useLocation();
  const routerLocation = useRouterLocation();
  const { gyms, loading, error, fetchGymData } = useContext(GymContext);
  // const [hasSeenLandingPage, setHasSeenLandingPage] = useState(() => {
  //   return localStorage.getItem('hasSeenLandingPage') === 'true';
  // });

  useEffect(() => {
    // Track page views with Google Analytics
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: routerLocation.pathname + routerLocation.search,
      });
    }
  }, [routerLocation]);

  // Fetch gym data based on location (latitude and longitude)
  useEffect(() => {
    if (location?.lat && location?.lon) {
      fetchGymData(); // No need to pass lat/lon since you fetch all gyms
    }
  }, [location, fetchGymData]);

  // Handle landing page visibility
  // useEffect(() => {
  //   if (window.location.pathname === '/landing' && !currentUser) {
  //     localStorage.setItem('hasSeenLandingPage', 'true');
  //     setHasSeenLandingPage(true);
  //   }
  // }, [currentUser]);

  return (
    <div>
      <Header />
      <div style={{ minHeight: '80vh' }}>
        <Routes >
          {/* <Route path="/landing" element={<LandingPage />} /> */}
          <Route
            path="/home"
            // element={currentUser || hasSeenLandingPage ? <HomePageRoute /> : <Navigate to="/landing" />}
          element={<HomePage/>}
          />
          <Route
            path="/gyms/:id"
            // element={currentUser || hasSeenLandingPage ? <GymDetails /> : <Navigate to="/landing" />}
            element={<GymDetails />}
          />
          {/* <Route path="/login" element={currentUser ? <Navigate to="/home" /> : <Login />} /> */}
          {/* <Route path="/signup" element={currentUser ? <Navigate to="/home" /> : <Signup />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          {/* <Route path="/news" element={currentUser || hasSeenLandingPage ? <News /> : <Navigate to="/landing" />} />
          <Route path="/profile" element={currentUser ? <UserProfile /> : <Navigate to="/landing" />} /> */}
          <Route path="/gyms/add-gym" element={<AddGym />} />
          <Route path="/gyms/edit/:id" element={<EditGym/>} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/add-gym" element={currentUser ? <AddGym /> : <Navigate to="/landing" />} /> */}
          {/* <Route path="*" element={<Navigate to={currentUser ? "/home" : "/landing"} />} /> */}
          {/* <Route path="*" element={<Navigate to={currentUser ? "/home" : "/landing"} />} /> */}
          {<Route path="*" element={<Navigate to="/home" />} />}
        </Routes>
      </div>
      <Footer />
    </div>
  );

  function HomePageRoute() {
    if (error) {
      return <p>Error: {error}</p>;
    }

    if (loading || !location?.lat || !location?.lon) {
      return <p>Loading location and gym data...</p>;
    }

    return <HomePage location={location} gyms={gyms} loading={loading} error={error} />;
  }
}

export default App;
