import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

const Contact = () => {
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('Feedback');
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const navigate = useNavigate(); // Hook to navigate to the homepage

    const closeNotification = () => {
        setShowStatus(false);
        navigate('/home'); // Redirect to the homepage
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/sheets/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    subject,
                    message,
                }),
            });

            if (response.ok) {
                setStatus('Thanks for your submission!');
                setName('');
                setEmail('');
                setMessage('');
                setShowStatus(true);

                // Automatically close after 3 seconds and redirect
                setTimeout(() => {
                    closeNotification();
                }, 3000);
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setStatus('Failed to send message');
            setShowStatus(true);

            // Automatically close after 3 seconds and redirect
            setTimeout(() => {
                closeNotification();
            }, 3000);
        }
    };

    return (
        <section style={styles.contactSection}>
            <div style={styles.card}>
                {!showStatus ? (
                    <>
                        <h2 style={styles.sectionHeading}>Contact Us</h2>
                        <p style={styles.contactText}>
                            We’d love to hear your thoughts! Let us know if you're submitting feedback, reporting a bug, or have business inquiries.
                        </p>
                        <form style={styles.contactForm} onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Your Name"
                                style={styles.input}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Your Email"
                                style={styles.input}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <select
                                style={styles.select}
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            >
                                <option value="Feedback">Feedback</option>
                                <option value="Bug Report">Bug Report</option>
                                <option value="Business Inquiry">Business Inquiry</option>
                            </select>
                            <textarea
                                placeholder="Your Message"
                                style={styles.textarea}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                            <Button
                                type="submit"
                                text="Send Message"
                                buttonType="primary"
                            />
                        </form>
                    </>
                ) : (
                    <div style={styles.statusContainer}>
                        <p style={styles.statusMessage}>{status}</p>
                        <button style={styles.closeButton} onClick={closeNotification}>
                            &times;
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
};

const styles = {
    contactSection: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
    },
    card: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '30px',
        borderRadius: '10px',
        maxWidth: '500px',
        width: '100%',
        textAlign: 'center',
    },
    sectionHeading: {
        fontSize: '28px',
        color: '#2c3e50',
        marginBottom: '20px',
    },
    contactText: {
        fontSize: '18px',
        color: '#7f8c8d',
        marginBottom: '20px',
    },
    contactForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    select: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        height: '100px',
        marginBottom: '20px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #333',
        borderRadius: '15px',
    },
    statusMessage: {
        fontSize: '16px',
        flex: 1,
        textAlign: 'left',
        padding: '80px',
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        color: '#333',
        position: 'absolute',
        top: '35px',
        right: '45px',
    },
};

export default Contact;
