import React from 'react';

const Schedule = ({ schedule }) => {
    // Styles for the schedule grid and slots
    const styles = {
        scheduleContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(8, 1fr)', // 7 columns for days of the week + 1 extra for the time slot
            gridTemplateRows: 'repeat(17, 40px)', // 17 rows for hours (adjust size as needed) + 1 extra for the header
            gap: '5px', // Space between grid items
        },
        headerContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(8, 1fr)',
            gap: '5px',
            marginBottom: '5px',
        },
        headerSlots: {
            display: 'flex',
            fontWeight: 'bold',
            border: '1px solid #ccc',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f0f0f0',
            marginTop: '15px',
        },
        slot: {
            border: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0f0', // Default greyed-out background
        },
        adults: {
            backgroundColor: '#4CAF50', // Green for adults
            color: 'white',
        },
        kids: {
            backgroundColor: '#FF5722', // Orange for kids
            color: 'white',
        },
    };

    // Days of the week
    const daysOfWeek = ['Time','Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

    // Render the grid slots dynamically based on the schedule
    return (
        <div>
            <div style={ styles.headerContainer}>
                {daysOfWeek.map((day) => (
                    <div key={day} style={ styles.headerSlots }>
                        {day}
                    </div>
                ))}
            </div>
            <div style={styles.scheduleContainer}>
                {/* Loop through the schedule grid */}
                {schedule.map((row, rowIndex) =>
                    row.map((slot, colIndex) => (
                        <div
                            key={`${rowIndex}-${colIndex}`}
                            style={{
                                ...styles.slot,
                                ...(slot === 'adults' ? styles.adults : {}),
                                ...(slot === 'kids' ? styles.kids : {}),
                                ...(typeof slot === 'string' && /^\d/.test(slot) ? styles.times : {}), // Check if slot starts with a number
                            }}
                        >
                            {slot === 'adults' ? 'Adults' : slot === 'kids' ? 'Kids' : slot}
                        </div>

                    ))
                )}
            </div>
        </div>
    );
};

export default Schedule;
