import React from 'react';

const Footer = () => {
    return (
        <footer style={styles.footer}>
            <div style={styles.rightsReserved}>
                <h4>© {new Date().getFullYear()} BJJLocal</h4>
                <h4>All Rights Reserved</h4>
            </div>
        </footer>
    );
};

const styles = {
    footer: {
        display: 'flex',  
        justifyContent: 'center',  
        alignItems: 'center',  
        height: '10vh',
        backgroundColor: '#333A3F',
        color: '#ecf0f1',
        bottom: 0,
        width: '100%',  // Ensure full width
    },
    rightsReserved: {
        margin: 0,  // Remove margin to ensure centering
    },
};

export default Footer;
