import React, { useState } from 'react';

const ContactInfo = ({ label, value, icon, onClick }) => {
    const [hovered, setHovered] = useState(false);

    const styles = {
        iconStyle: {
            marginRight: '10px', // Add margin to the right to space the icon from the text
            width: '24px',
            height: '24px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease',
            transform: hovered ? 'scale(1.2)' : 'scale(1)', // Scale only on hover
        },
        infoRow: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
        },
        labelStyle: {
            marginRight: '10px',
        },
    };

    return value ? (
        <div style={styles.infoRow}>
            {icon && (
                <img
                    src={icon}
                    alt={`${label} icon`}
                    style={styles.iconStyle}
                    onClick={onClick}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                />
            )}
            <strong style={styles.labelStyle}>{label}:</strong>
            <p>{value}</p>
        </div>
    ) : null;
};

export default ContactInfo;
