import React, { createContext, useState, useCallback, useMemo } from 'react';

export const GymContext = createContext();

export const GymProvider = ({ children }) => {
    const [gyms, setGyms] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch all gyms without any filters
    const fetchGymData = useCallback(async () => {
        try {
            setLoading(true);

            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/gyms/`); // Fetch all gyms
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setGyms(data); // Replace current gyms with the fetched data
        } catch (err) {
            console.error('Error fetching gym data:', err);
            setError(err.message || 'Unknown error occurred');
        } finally {
            setLoading(false);
        }
    }, []); // Empty dependency array to ensure it's only created once

    // Memoize the value to prevent re-rendering unless dependencies change
    const value = useMemo(() => ({
        gyms,
        loading,
        error,
        fetchGymData,
    }), [gyms, loading, error, fetchGymData]);

    return (
        <GymContext.Provider value={value}>
            {children}
        </GymContext.Provider>
    );
};
