import React, { useState } from 'react';
import websiteIcon from '../assets/icons/website-icon.png';
import facebookIcon from '../assets/icons/facebook-icon.png';
import instagramIcon from '../assets/icons/instagram-icon.png';
import youtubeIcon from '../assets/icons/youtube-icon.png';
import xIcon from '../assets/icons/x-icon.png';

const SocialMediaIcons = ({ gym }) => {

    const [hoveredIcon, setHoveredIcon] = useState(null);

    const styles = {
        socialIconsContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '5px',
        },
        socialIcon: {
            width: '34px',
            height: '34px',
            margin: '0 10px',
            cursor: 'pointer',
            transition: 'transform 0.3s ease',
        },
        socialIconHover: {
            transform: 'scale(1.2)',  
        },
    };

    const handleIconHover = (icon) => {
        setHoveredIcon(icon);
    };

    const handleIconLeave = () => {
        setHoveredIcon(null);
    };

    // Construct social media URLs from usernames
    const socialMediaLinks = {
        facebook: `https://www.facebook.com/${gym.social_media?.facebook || ''}`,
        instagram: `https://www.instagram.com/${gym.social_media?.instagram || ''}`,
        twitter: `https://twitter.com/${gym.social_media?.twitter || ''}`,
        youtube: `https://www.youtube.com/${gym.social_media?.youtube || ''}`,
    };

    const socialMediaIcons = {
        facebook: { icon: facebookIcon, name: 'facebook' },
        instagram: { icon: instagramIcon, name: 'instagram' },
        twitter: { icon: xIcon, name: 'x' },
        youtube: { icon: youtubeIcon, name: 'youtube' },
    };

    return (
        <div style={styles.socialIconsContainer}>
            {/* Website Icon */}
            {gym.website && (
                <a href={gym.website} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                    <img
                        src={websiteIcon}
                        alt="website icon"
                        style={{
                            ...styles.socialIcon,
                            ...(hoveredIcon === 'website' && styles.socialIconHover),
                        }}
                        onMouseEnter={() => handleIconHover('website')}
                        onMouseLeave={handleIconLeave}
                    />
                </a>
            )}

            {/* Social Media Icons */}
            {gym.social_media &&
                Object.entries(socialMediaIcons).map(([key, { icon, name }]) => (
                    gym.social_media[key] && (
                        <a
                            key={key}
                            href={socialMediaLinks[key]}  // Use constructed URL
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}  // Prevent card click
                        >
                            <img
                                src={icon}
                                alt={`${name} icon`}
                                style={{
                                    ...styles.socialIcon,
                                    ...(hoveredIcon === name && styles.socialIconHover),
                                }}
                                onMouseEnter={() => handleIconHover(name)}
                                onMouseLeave={handleIconLeave}
                            />
                        </a>
                    )
                ))}
        </div>
    );

};

export default SocialMediaIcons;
