import React from 'react';
import PropTypes from 'prop-types'; // For prop type validation

// Define a color mapping based on button types
const colorOptions = {
    primary: {
        background: '#3498db',
        color: '#fff',
    },
    secondary: {
        background: '#2ecc71',
        color: '#fff',
    },
    danger: {
        background: '#e74c3c',
        color: '#fff',
    },
    warning: {
        background: '#f39c12',
        color: '#fff',
    },
    default: {
        background: '#bdc3c7',
        color: '#2c3e50',
    },
};

const Button = ({ text, onClick, buttonType = 'default', disabled = false }) => {
    const styles = {
        ...colorOptions[buttonType],
        padding: '10px 20px',
        fontSize: '15px',
        border: 'none',
        borderRadius: '5px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
    };

    return (
        <button style={styles} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired, // Button text
    onClick: PropTypes.func, // Click handler function
    buttonType: PropTypes.oneOf(['primary', 'secondary', 'danger', 'warning', 'default']), // Button style type
    disabled: PropTypes.bool, // Whether the button is disabled
};

export default Button;
