import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

// Create the LocationContext
const LocationContext = createContext();

// Custom hook to use the LocationContext
export const useLocation = () => useContext(LocationContext);

// LocationProvider component to wrap around parts of your app that need location
export const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState({ lat: null, lon: null, city: null });
    const [fetchAttempts, setFetchAttempts] = useState(0);

    const fetchLocationFromIPAPI = useCallback(async () => {
        try {
            const response = await fetch('https://ipapi.co/json/');
            if (!response.ok) {
                throw new Error('Error fetching IP-based location.');
            }
            const data = await response.json();
            setLocation({ lat: data.latitude, lon: data.longitude, city: data.city });
        } catch (error) {
            console.error('Error with IP location fetch:', error);
            setFetchAttempts(prevAttempts => prevAttempts + 1);
        }
    }, []);

    const fetchLocationFromBrowser = useCallback(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lon: longitude, city: 'Unknown' });
                },
                (error) => {
                    console.error('Error getting browser geolocation:', error);
                    fetchLocationFromIPAPI();
                }
            );
        } else {
            // If browser doesn't support geolocation
            fetchLocationFromIPAPI();
        }
    }, [fetchLocationFromIPAPI]);

    useEffect(() => {
        // Destructure location for dependency tracking
        const { lat, lon, city } = location;

        // Only fetch location if it's not already set
        if (lat === null && lon === null && city === null) {
            if (fetchAttempts < 3) {
                fetchLocationFromBrowser();
            } else {
                // Default to Dallas, Texas after 3 failed attempts
                setLocation({ lat: 32.7767, lon: -96.7970, city: 'Dallas' });
            }
        }
    }, [location, fetchAttempts, fetchLocationFromBrowser]);

    // Retry fetching location every 2 seconds if it's still null and attempts are less than 3
    useEffect(() => {
        if (location.lat === null && location.lon === null && location.city === null && fetchAttempts < 3) {
            const retryInterval = setInterval(() => {
                fetchLocationFromBrowser();
            }, 2000);

            return () => clearInterval(retryInterval); // Cleanup interval on component unmount or success
        }
    }, [location.lat, location.lon, location.city, fetchAttempts, fetchLocationFromBrowser]);

    // Function to update location manually
    const updateLocation = (newLocation) => {
        setLocation(newLocation);
        setFetchAttempts(3); // Stop retries if location is manually updated
    };

    return (
        <LocationContext.Provider value={{ location, updateLocation }}>
            {children}
        </LocationContext.Provider>
    );
};

export default LocationContext;
