import React from 'react';

const GoogleMapEmbed = ({ address }) => {
    const encodedAddress = encodeURIComponent(address);
    const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyBKZh1erisKQjAmvBNC-J2hghiG7SZ5ZJc&q=${encodedAddress}`;

    return (
        <div style={styles.mapContainer}>
            <iframe
                title="Google Map"
                src={googleMapUrl}
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
            />
        </div>
    );
};

const styles = {
    mapContainer: {
        width: '100%',
        height: '400px',
        marginTop: '20px',
    },
};

export default GoogleMapEmbed;
