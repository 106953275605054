import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import { useAuth } from '../contexts/AuthContext';
import Button from '../components/Button';

const Signup = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [beltRank, setBeltRank] = useState('White'); // Default belt rank
    const [errors, setErrors] = useState({});
    const { login } = useAuth();
    const navigate = useNavigate();  // Hook to navigate programmatically

    // Basic form validation
    const validateForm = () => {
        const newErrors = {};

        if (!firstName.trim()) newErrors.firstName = 'First name is required';
        if (!lastName.trim()) newErrors.lastName = 'Last name is required';

        // Email validation (simple regex for structure)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) newErrors.email = 'Invalid email address';

        // Password validation (min length 8 characters)
        if (password.length < 8) newErrors.password = 'Password must be at least 8 characters';
        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return; // Stop submission if validation fails

        const userData = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            password,
            beltRank,
        };

        try {
            const response = await fetch('/api/v1/users/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                throw new Error(message);
            }

            const data = await response.json();
            console.log('User created:', data);

            // Simulate login after successful signup with the received token
            login(data.token);

            // Clear form fields
            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setBeltRank('White');
        } catch (error) {
            console.error('Error during signup:', error.message);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.marketingColumn}>
                <h2 style={styles.hookHeading}>Limited Time Offer!</h2>
                <p style={styles.hookText}>
                    Since we're a new app, sign up now and get the next 12 months for <strong>$1 per month</strong>. Normally it costs $5 per month!
                </p>
                <p style={styles.hookText}>
                    By signing up, you can add gyms, edit gyms, leave comments, and leave ratings. Plus, you'll have access to all future features for free!
                </p>
            </div>
            <div style={styles.formColumn}>
                <h2 style={styles.heading}>Sign Up</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>First Name:</label>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            style={styles.input}
                        />
                        {errors.firstName && <p style={styles.error}>{errors.firstName}</p>}
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Last Name:</label>
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            style={styles.input}
                        />
                        {errors.lastName && <p style={styles.error}>{errors.lastName}</p>}
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={styles.input}
                        />
                        {errors.email && <p style={styles.error}>{errors.email}</p>}
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                        {errors.password && <p style={styles.error}>{errors.password}</p>}
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                        {errors.confirmPassword && <p style={styles.error}>{errors.confirmPassword}</p>}
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Belt Rank:</label>
                        <select
                            value={beltRank}
                            onChange={(e) => setBeltRank(e.target.value)}
                            required
                            style={styles.input}
                        >
                            <option value="White">White</option>
                            <option value="Blue">Blue</option>
                            <option value="Purple">Purple</option>
                            <option value="Brown">Brown</option>
                            <option value="Black">Black</option>
                        </select>
                    </div>
                    <Button
                        text="Sign Up"
                        type="submit"
                        buttonType="primary"
                    />
                </form>

                {/* Add "Already have an account?" link */}
                <div style={styles.loginRedirect}>
                    <p>Already have an account?</p>
                    <button
                        onClick={() => navigate('/login')}
                        style={styles.loginLink}
                    >
                        Log in here!
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '20px',
        margin: '0 auto',
    },
    marketingColumn: {
        flex: '1',
        padding: '20px',
        marginRight: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f8f9fa',
    },
    formColumn: {
        flex: '1',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '30vw',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
    },
    hookHeading: {
        fontSize: '24px',
        marginBottom: '20px',
        color: '#333',
    },
    hookText: {
        fontSize: '16px',
        color: '#555',
        lineHeight: '1.6',
        marginBottom: '20px',
    },
    heading: {
        marginBottom: '20px',
        fontSize: '24px',
        color: '#333',
    },
    form: {
        width: '100%',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '16px',
        color: '#666',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    error: {
        color: 'red',
        fontSize: '12px',
    },
    loginRedirect: {
        marginTop: '20px',
        textAlign: 'center',
    },
    loginLink: {
        background: 'none',
        border: 'none',
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '5px',
    },
};

export default Signup;
