import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import backIcon from '../assets/icons/back-icon.png';
import Button from '../components/Button';

const EditGym = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        region: '',
        phone: '',
        email: '',
        website: '',
        description: '',
        facebook: '',
        instagram: '',
        youtube: '',
        schedule: [],
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [hoveredIcon, setHoveredIcon] = useState(null);

    useEffect(() => {
        const fetchGymDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/gyms/${id}`);
                if (!response.ok) throw new Error('Failed to fetch gym details');

                const data = await response.json();
                console.log(data)
                setFormData({
                    name: data.name || '',
                    address: data.location?.formatted_address || '',
                    region: data.region || '',
                    phone: data.tel || '',
                    email: data.email || '',
                    website: data.website || '',
                    description: data.description || '',
                    facebook: data.social_media.facebook || '',
                    instagram: data.social_media.instagram || '',
                    youtube: data.social_media.youtube || '',
                    schedule: data.schedule || [],
                });
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGymDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage('');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/gyms/${id}/edit`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error(`Failed to update gym details: ${response.statusText}`);

            setSuccessMessage('Gym updated successfully!');
        } catch (error) {
            setError(error.message);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div style={styles.pageContainer}>
            <div style={styles.container}>
                <div style={styles.rowOne}>
                    <div style={styles.backButton} onClick={() => navigate(-1)}>
                        <img
                            src={backIcon}
                            alt="Go Back"
                            style={{
                                ...styles.backIconStyle,
                                ...(hoveredIcon === 'back' && styles.backIconHover),  // Apply hover style if hovered
                            }}
                            onMouseEnter={() => setHoveredIcon('back')}
                            onMouseLeave={() => setHoveredIcon(null)}
                        />
                    </div>
                </div>

                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Address:</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Region:</label>
                        <input
                            type="text"
                            name="region"
                            value={formData.region}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Phone:</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Website:</label>
                        <input
                            type="text"
                            name="website"
                            value={formData.website}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Description:</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Facebook:</label>
                        <input
                            type="text"
                            name="facebook"
                            value={formData.facebook}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Instagram:</label>
                        <input
                            type="text"
                            name="instagram"
                            value={formData.instagram}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>YouTube:</label>
                        <input
                            type="text"
                            name="youtube"
                            value={formData.youtube}
                            onChange={handleInputChange}
                            style={styles.input}
                        />
                    </div>

                    {successMessage && <p style={styles.success}>{successMessage}</p>}
                    {error && <p style={styles.error}>{error}</p>}
                    <Button type="submit" text="Save Changes" buttonType="primary" />
                </form>
            </div>
        </div>
    );
};

const styles = {
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        padding: '20px',
    },
    rowOne: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    backIconStyle: {
        width: '42px',
        height: '42px',
        transition: 'transform 0.3s ease',
    },
    backIconHover: {
        transform: 'scale(1.2)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        marginBottom: '5px',
        fontSize: '16px',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    success: {
        color: 'green',
        fontSize: '14px',
        marginBottom: '10px',
    },
    error: {
        color: 'red',
        fontSize: '14px',
        marginBottom: '10px',
    },
};

export default EditGym;
