import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import googleMapsIcon from '../assets/icons/google-maps-icon.png';
import phoneIcon from '../assets/icons/phone-icon.png';
import emailIcon from '../assets/icons/email-icon.png';
import backIcon from '../assets/icons/back-icon.png';
import ContactInfo from '../components/ContactInfo';
import SocialMediaIcons from '../components/SocialMediaIcons';
import Schedule from '../components/GymSchedule';
import GoogleMapEmbed from '../components/GoogleMaps';
import Button from '../components/Button';

const GymDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [gym, setGym] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);

    useEffect(() => {
        const fetchGymDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/gyms/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch gym details');
                }
                const data = await response.json();
                if (data) {
                    setGym(data);  // Ensure gym data is properly set
                } else {
                    throw new Error('Gym not found');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGymDetails();
    }, [id]);

    const openGoogleMaps = (address) => {
        const encodedAddress = encodeURIComponent(address);
        window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
    };

    const makePhoneCall = (tel) => {
        window.open(`tel:${tel}`, '_self');
    };

    const sendEmail = (email) => {
        if (email) {
            const mailtoLink = document.createElement('a');  // Create an anchor element
            mailtoLink.href = `mailto:${email}`;
            mailtoLink.click();  // Programmatically click the link
            console.log('Email link clicked for: ', email);
        }
    };

    function getHourIndexFromTime(time) {
        const [hour, modifier] = time.split(' ');
        let hourIndex = parseInt(hour.split(':')[0], 10);

        if (modifier === 'PM' && hourIndex !== 12) {
            hourIndex += 12;
        } else if (modifier === 'AM' && hourIndex === 12) {
            hourIndex = 0;  // Midnight case
        }
        return hourIndex - 5;  // Align with the 5 AM start time
    }

    function transformSchedule(gymSchedule) {
        const scheduleGrid = Array.from({ length: 17 }, () => Array(7).fill(null));  // 17 hours, 7 days

        gymSchedule.forEach(({ dayOfWeek, time, ageGroup }) => {
            const dayIndex = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].indexOf(dayOfWeek);
            const hourIndex = getHourIndexFromTime(time);  // Convert '6:00 PM' to index

            if (dayIndex >= 0 && hourIndex >= 0) {
                scheduleGrid[hourIndex][dayIndex] = ageGroup === 'adult' ? 'adults' : 'kids';
            }
        });

        return scheduleGrid;
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!gym) return <p>Gym not found</p>;

    return (
        <div style={styles.pageContainer}>
            <div style={styles.container}>
                {/* Row One: Back Button & Social Icons */}
                <div style={styles.rowOne}>
                    <div style={styles.backButton} onClick={() => navigate(-1)}>
                        <img
                            src={backIcon}
                            alt="Go Back"
                            style={{
                                ...styles.backIconStyle,
                                ...(hoveredIcon === 'back' && styles.backIconHover),  // Apply hover style if hovered
                            }}
                            onMouseEnter={() => setHoveredIcon('back')}
                            onMouseLeave={() => setHoveredIcon(null)}
                        />
                    </div>
                    <div style={styles.socialContainer}>
                        <SocialMediaIcons gym={gym} />
                    </div>
                    
                </div>

                {/* Gym Logo and Name */}
                <div style={styles.rowTwo}>
                    {gym.logo ? (
                        <img src={gym.logo} alt="Gym" style={styles.gymLogo} />
                    ) : null}
                    <h2 style={styles.heading}>{gym.name}</h2>
                </div>

                {/* <Button
                    text="Edit"
                    onClick={() => navigate(`/gyms/edit/${id}`)}
                    buttonType="danger"  // Use the 'primary' color scheme
                /> */}

                {/* Gym Description */}
                <div style={styles.rowThree}>
                    <p style={styles.description}>{gym.description}</p>
                </div>

                {/* Contact Information & Gym Info */}
                <div style={styles.rowFour}>
                    <div style={styles.contactContainer}>
                        <div style={styles.details}>
                            <ContactInfo
                                label="Address"
                                value={gym.location?.formatted_address}
                                icon={googleMapsIcon}
                                onClick={() => openGoogleMaps(gym.location?.formatted_address)}
                            />
                            <ContactInfo
                                label="Phone"
                                value={gym.tel}
                                icon={phoneIcon}
                                onClick={() => makePhoneCall(gym.tel)}
                            />
                            <ContactInfo
                                label="Email"
                                value={gym.email}
                                icon={emailIcon}
                                onClick={() => sendEmail(gym.email)}
                            />
                        </div>
                    </div>

                    <div style={styles.gymDetailsContainer}>                        
                        {gym.location?.formatted_address && (
                            <GoogleMapEmbed address={gym.location.formatted_address} />
                        )}

                    </div>

                    {gym.schedule && gym.schedule.length > 0 && (
                        <div style={styles.scheduleContainer}>
                            <h2>Weekly Schedule</h2>
                            <Schedule schedule={transformSchedule(gym.schedule)} />
                        </div>
                    )}

                </div>

                {/* Footer Info */}
                <div style={styles.pageFooter}>
                    <p>Last Updated: {new Date(gym.lastUpdated).toLocaleDateString('en-US')}</p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    pageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        padding: '20px',
    },
    rowOne: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    backIconStyle: {
        width: '42px',
        height: '42px',
        transition: 'transform 0.3s ease',
    },
    backIconHover: {
        transform: 'scale(1.2)',
    },
    rowTwo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    gymLogo: {
        maxWidth: '100px',
        maxHeight: '100px',
        marginRight: '15px',
    },
    heading: {
        fontSize: '42px',
        color: '#333',
    },
    rowThree: {
        padding: '20px 0',
        textAlign: 'center',
    },
    description: {
        fontSize: '20px',
        color: '#555',
    },
    rowFour: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '20px',
        marginBottom: '20px',
    },
    contactContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '15px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Add a shadow for depth
        display: 'flex',
    },
    details: {
        fontSize: '18px',
        color: '#666', 
    },
    gymDetailsContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '15px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    scheduleContainer: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '15px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    pageFooter: {
        marginTop: '30px',
        textAlign: 'center',
    },
};

export default GymDetails;
