import React from 'react';

const ErrorComponent = ({ error }) => {
    return (
        <div style={{ margin: 'auto', textAlign: 'center' }}>
            <p>Something went wrong...</p>
            <p>{error}</p>
        </div>
    );
};

export default ErrorComponent;
