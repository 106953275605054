import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';

const AddGym = () => {
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        website: '',
        description: '',
        phone: '',
        email: '',
        facebook: '',
        instagram: '',
        youtube: '',
        price: '',
        schedule: [{ dayOfWeek: '', time: '', giOrNoGi: '', ageGroup: '', difficulty: '' }],
        membershipTiers: [{ name: '', price: '', features: '' }],
        promoCodes: [{ code: '', discountPercentage: '', validUntil: '' }],
        classBookingLink: '',
        trialSignUpLink: ''
    });
    const [statusMessage, setStatusMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const closeNotification = () => {
        setShowNotification(false);
            navigate('/home');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowNotification(false);
        setIsSuccess(null);

        if (!formData.name || !formData.address || !formData.phone || !formData.email || !formData.website || !formData.description) {
            setStatusMessage('Please fill out all required fields.');
            setIsSuccess(false);
            setShowNotification(true);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/sheets/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error(`An error occurred: ${response.statusText}`);

            const data = await response.json();
            setStatusMessage('Request to add gym sent!');
            setIsSuccess(true);
            setShowNotification(true);
            setFormData({
                name: '',
                address: '',
                website: '',
                description: '',
                phone: '',
                email: '',
                facebook: '',
                instagram: '',
                youtube: '',
                price: '',
                schedule: [{ dayOfWeek: '', time: '', giOrNoGi: '', ageGroup: '', difficulty: '' }],
                membershipTiers: [{ name: '', price: '', features: '' }],
                promoCodes: [{ code: '', discountPercentage: '', validUntil: '' }],
                classBookingLink: '',
                trialSignUpLink: ''
            });

            setTimeout(closeNotification, 3000); // Automatically close and navigate after 3 seconds
        } catch (error) {
            console.error("Submission error:", error);
            setStatusMessage('Failed to add gym. Please try again.');
            setIsSuccess(false);
            setShowNotification(true);

            setTimeout(closeNotification, 3000);
        }
    };

    return (
        <div style={styles.container}>
            <h2>Add a New Gym</h2>
            {showNotification && (
                <div style={{
                    ...styles.statusContainer,
                    backgroundColor: isSuccess ? '#d4edda' : '#f8d7da',
                    color: isSuccess ? '#155724' : '#721c24'
                }}>
                    <p style={styles.statusMessage}>{statusMessage}</p>
                    <button style={styles.closeButton} onClick={closeNotification}>
                        &times;
                    </button>
                </div>
            )}
            <form onSubmit={handleSubmit} style={styles.form}>
                {['name', 'address', 'phone', 'email', 'website', 'description', 'facebook', 'instagram', 'youtube'].map(field => (
                    <div style={styles.formGroup} key={field}>
                        <label style={styles.label}>{field.charAt(0).toUpperCase() + field.slice(1)} {['name', 'address', 'phone', 'email', 'website', 'description'].includes(field) && '(required)'}</label>
                        <input
                            type={field === 'email' ? 'email' : 'text'}
                            name={field}
                            value={formData[field]}
                            onChange={handleInputChange}
                            required={['name', 'address', 'phone', 'email', 'website', 'description'].includes(field)}
                            style={styles.input}
                        />
                    </div>
                ))}
                <Button type="submit" text="Add Gym" buttonType="primary" />
            </form>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '500px',
        margin: '25px auto',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontSize: '16px',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        fontSize: '16px',
    },
    statusMessage: {
        flex: 1,
        margin: 0,
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
        color: '#000',
    }
};

export default AddGym;
