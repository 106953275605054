import React from 'react';


const LoadingComponent = () => {
    return (
        <div style={{
            margin: 'auto'
        }}>
            <p> Loading...</p>
        </div>
    )
}

export default LoadingComponent;



