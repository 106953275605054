import React, { useState } from 'react';

const SearchComponent = ({ searchText, onSearchChange }) => {
    const [isFocused, setIsFocused] = useState(false); // Track focus state

    return (
        <div>
            <input
                type="text"
                placeholder=" Search gyms..."
                value={searchText}
                onChange={(e) => onSearchChange(e.target.value)}
                style={{
                    ...styles.searchBar,
                    borderColor: isFocused ? '#ff8927' : 'lightgrey'
                }}
                onFocus={() => setIsFocused(true)} // Set focus state to true
                onBlur={() => setIsFocused(false)} // Set focus state to false
            />
        </div>
    );
};

const styles = {
    searchBar: {
        padding: '10px',
        width: '250px',
        borderRadius: '25px', 
        border: '2px solid lightgrey', 
        outline: 'none', 
        transition: 'border-color 0.3s ease', 
    },
};

export default SearchComponent;
