import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import { useAuth } from '../contexts/AuthContext';

const Header = () => {
    // const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const [hoveredIndex, setHoveredIndex] = useState(null);

    // const handleLogout = async () => {
    //     try {
    //         await logout();
    //         navigate('/login');
    //     } catch (error) {
    //         console.error("Failed to log out:", error);
    //     }
    // };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const navItems = [
        { path: '/home', label: 'Home' },
        { path: '/gyms/add-gym', label: 'Add Gym' },
        { path: '/contact', label: 'Contact' },
        // { path: '/profile', label: 'Profile', requiresAuth: true },
    ];

    return (
        <header style={styles.header}>
            <div
                style={styles.logo}
                onClick={() => navigate('/home')}
            >
                <h1><em>BJJ</em></h1>
                <h1 style={styles.headerLocal}><em>Local</em></h1>
            </div>
            <nav>
                <ul style={styles.navList}>
                    {navItems.map((item, index) => (
                        <li
                            key={index}
                            style={{
                                ...styles.navItem,
                                color: hoveredIndex === index ? '#ff8927' : '#ecf0f1',
                                transform: hoveredIndex === index ? 'scale(1.2)' : 'scale(1)',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to={item.path} style={styles.navLink}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
            {/* <div style={styles.auth}>
                {currentUser ? (
                    <button onClick={handleLogout} style={styles.authButton}>Logout</button>
                ) : (
                    <>
                        <button onClick={() => navigate('/login')} style={styles.authButton}>Login</button>
                        <button onClick={() => navigate('/signup')} style={styles.signupButton}>Sign Up</button>
                    </>
                )}
            </div> */}
        </header>
    );
};

const styles = {
    header: {
        minHeight: '12vh',
        backgroundColor: '#333A3F',
        color: '#ecf0f1',
        alignItems: 'center',
        padding: '0 20px',
    },
    logo: {
        fontSize: '24px',
        fontWeight: 'bold',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
    },
    headerLocal: {
        color: '#ff8927'
    },
    navList: {
        display: 'flex',
        justifyContent: 'center',
        listStyleType: 'none',
        gap: '20px',
        padding: 0,
        margin: 0,
    },
    navItem: {
        fontSize: '18px',
        transition: 'transform 0.3s ease, color 0.3s ease',
    },
    navLink: {
        textDecoration: 'none',
        color: 'inherit', // Inherit color from parent
        fontWeight: 'bold',
    },
    auth: {
        display: 'flex',
        gap: '10px',
    },
    authButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#3498db',
        color: '#ecf0f1',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    signupButton: {
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#e74c3c',
        color: '#ecf0f1',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

export default Header;
